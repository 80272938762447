import * as types from "./types.js";


export const setSocketMonitorOn = () => ({
    type: types.SET_SOCKET_MONITOR_STATUS_ON,
});

export const setSocketMonitorOff = () => ({
    type: types.SET_SOCKET_MONITOR_STATUS_OFF,
});

export const setHeartbeatMonitorOn = () => ({
    type: types.SET_HEARTBEAT_MONITOR_STATUS_ON,
});

export const setHeartbeatMonitorOff = () => ({
    type: types.SET_HEARTBEAT_MONITOR_STATUS_OFF,
});
