import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    isSocketMonitorOnline: false,
    isHeartbeatMonitorOnline: false,
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_SOCKET_MONITOR_STATUS_ON: {
            return {
                ...state,
                isSocketMonitorOnline: true,
            };
        }

        case types.SET_SOCKET_MONITOR_STATUS_OFF: {
            return {
                ...state,
                isSocketMonitorOnline: false,
            };
        }

        /* --- */

        case types.SET_HEARTBEAT_MONITOR_STATUS_ON: {
            return {
                ...state,
                isHeartbeatMonitorOnline: true,
            };
        }

        case types.SET_HEARTBEAT_MONITOR_STATUS_OFF: {
            return {
                ...state,
                isHeartbeatMonitorOnline: false,
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
